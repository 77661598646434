import React from 'react';
import type {IProduct} from '../../../../types/galleryTypes';
import {ProductType} from '../../../../types/galleryTypes';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import s from './ProductPrice.scss';
import {classes as productPriceStylable, st as productPriceStyles} from './ProductPrice.st.css';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';
import {PriceBreakdown} from '@wix/wixstores-client-common-components/dist/es/src/PriceBreakdown/PriceBreakdown';
import {BaseGalleryStore} from '../../../../viewerScript/BaseGalleryStore';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {
  formattedPrimaryPrice,
  formattedSecondaryPrice,
  hasAnyDiscount,
  hasAutomaticDiscount,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ConditionalRender} from '../../../../category/components/ConditionalRender/ConditionalRender';
import {Dialog, Text} from 'wix-ui-tpa/cssVars';
import classNames from 'classnames';
import {IProductPreOrderAvailability} from '@wix/wixstores-client-core/dist/es/src/types/product';

export enum DataHook {
  SrPriceBeforeDiscount = 'sr-product-item-price-before-discount',
  SrPriceToPay = 'sr-product-item-price-to-pay',
  SrPriceRange = 'st-price-range',
  OutOfStock = 'product-item-out-of-stock',
  PriceBeforeDiscount = 'product-item-price-before-discount',
  PriceToPay = 'product-item-price-to-pay',
  PriceRange = 'price-range-from',
  BasePriceComponent = 'base-price-component',
  DiscountRuleName = 'product-discount-rule-name',
}

interface TextMapProps {
  productPriceBeforeDiscountSR: string;
  productOutOfStockText: string;
  productPriceAfterDiscountSR: string;
  productPriceWhenThereIsNoDiscountSR: string;
  measurementUnits?: {[key: string]: {[key: string]: string}};
  pricePerUnitSR?: string;
}

export interface IProductPriceProps extends IGalleryGlobalProps {
  product: IProduct;
  allowFreeProducts: boolean;
  textsMap: TextMapProps;
  fromPrice?: string;
  priceBreakdown: BaseGalleryStore['priceBreakdown'];
  sendClickShippingInfoLinkSf: Function;
  isRTL: boolean;
  shouldUseCommonDiscountPricingMethods: boolean;
  shouldMoveDiscountNameUnderPrice: boolean;
  shouldAddStrikethroughAndSalePriceDesign: boolean;
}

const getPriceBeforeDiscount = ({
  textsMap,
  secondaryPrice,
  shouldAddStrikethroughAndSalePriceDesign,
}: {
  textsMap: TextMapProps;
  secondaryPrice: string;
  shouldAddStrikethroughAndSalePriceDesign: boolean;
}) => {
  return (
    <>
      <span className={a11y.srOnly} data-hook={DataHook.SrPriceBeforeDiscount}>
        {textsMap.productPriceBeforeDiscountSR}
      </span>
      <span
        data-hook={DataHook.PriceBeforeDiscount}
        className={shouldAddStrikethroughAndSalePriceDesign ? s.priceBeforeDiscountExperimentOn : s.priceBeforeDiscount}
        data-wix-original-price={secondaryPrice}>
        {secondaryPrice}
      </span>
    </>
  );
};

class PriceRangeComp extends React.Component<
  {
    formattedFromPrice: string;
    textsMap: TextMapProps;
    shouldAddStrikethroughAndSalePriceDesign: boolean;
  } & IProvidedTranslationProps
> {
  public render() {
    const {formattedFromPrice, textsMap, t, shouldAddStrikethroughAndSalePriceDesign} = this.props;
    return (
      <>
        <span className={a11y.srOnly} data-hook={DataHook.SrPriceRange}>
          {textsMap.productPriceWhenThereIsNoDiscountSR}
        </span>
        <span
          data-hook={DataHook.PriceRange}
          className={classNames(s.priceFrom, {
            [s.newSalePriceMargin]: shouldAddStrikethroughAndSalePriceDesign,
          })}>
          {t('priceRangeText', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }
}

const PriceRange = withGlobals(withTranslations()(PriceRangeComp));

const RegularPrice = ({
  hasDiscount,
  product,
  textsMap,
  shouldUseCommonDiscountPricingMethods,
  shouldAddStrikethroughAndSalePriceDesign,
}: {
  hasDiscount: boolean;
  product: IProduct;
  textsMap: TextMapProps;
  shouldUseCommonDiscountPricingMethods: boolean;
  shouldAddStrikethroughAndSalePriceDesign: boolean;
}) => {
  const primaryPrice = getPrimaryPrice({
    product,
    hasDiscount,
    shouldUseCommonDiscountPricingMethods,
  });

  return (
    <>
      {hasDiscount &&
        getSecondaryPrice({
          product,
          textsMap,
          shouldUseCommonDiscountPricingMethods,
          shouldAddStrikethroughAndSalePriceDesign,
        })}
      {
        <>
          <span className={a11y.srOnly} data-hook={DataHook.SrPriceToPay}>
            {hasDiscount ? textsMap.productPriceAfterDiscountSR : textsMap.productPriceWhenThereIsNoDiscountSR}
          </span>
          <span
            data-hook={DataHook.PriceToPay}
            className={classNames({
              [s.salePrice]: shouldAddStrikethroughAndSalePriceDesign && hasDiscount,
              [s.priceToPay]: !shouldAddStrikethroughAndSalePriceDesign || !hasDiscount,
              [s.newSalePriceMargin]: shouldAddStrikethroughAndSalePriceDesign,
            })}
            data-wix-price={primaryPrice}>
            {primaryPrice}
          </span>
        </>
      }
    </>
  );
};

function getSecondaryPrice({
  product,
  textsMap,
  shouldUseCommonDiscountPricingMethods,
  shouldAddStrikethroughAndSalePriceDesign,
}: {
  product: IProduct;
  textsMap: TextMapProps;
  shouldUseCommonDiscountPricingMethods: boolean;
  shouldAddStrikethroughAndSalePriceDesign: boolean;
}) {
  if (shouldUseCommonDiscountPricingMethods) {
    const selectedSecondaryPrice = formattedSecondaryPrice(product);
    return getPriceBeforeDiscount({
      textsMap,
      secondaryPrice: selectedSecondaryPrice,
      shouldAddStrikethroughAndSalePriceDesign,
    });
  } else {
    const isDiscountsExist =
      Boolean(product.itemDiscount?.priceAfterDiscount) && Boolean(product.formattedComparePrice);
    const selectedSecondaryPrice = isDiscountsExist ? product.formattedComparePrice : product.formattedPrice;

    return getPriceBeforeDiscount({
      textsMap,
      secondaryPrice: selectedSecondaryPrice,
      shouldAddStrikethroughAndSalePriceDesign,
    });
  }
}

function getPrimaryPrice({
  product,
  hasDiscount,
  shouldUseCommonDiscountPricingMethods,
}: {
  product: IProduct;
  hasDiscount: boolean;
  shouldUseCommonDiscountPricingMethods: boolean;
}) {
  if (shouldUseCommonDiscountPricingMethods) {
    const itemDiscount = product.itemDiscount;
    hasAutomaticDiscount(product);
    return formattedPrimaryPrice({...product, itemDiscount});
  } else {
    const discountPrice = product.itemDiscount?.priceAfterDiscount || product.formattedComparePrice;

    return hasDiscount ? discountPrice : product.formattedPrice;
  }
}

const BasePriceContainer = ({product, textsMap}) => {
  const getBasePriceTranslationSR = (translation, vars) => {
    //eslint-disable-next-line prefer-named-capture-group
    return translation.replace(/\{\{([^}]+)\}\}/gi, (_match, k) => {
      return vars[k.trim()];
    });
  };

  const {
    formattedPricePerUnit,
    pricePerUnitData: {baseQuantity, baseMeasurementUnit},
  } = product;

  const noun = baseQuantity === 1 ? 'singular' : 'plural';
  const unitTranslation = textsMap.measurementUnits[baseMeasurementUnit].abbr;
  const screenReaderText = getBasePriceTranslationSR(textsMap.pricePerUnitSR, {
    basePrice: formattedPricePerUnit,
    units: `${baseQuantity} ${textsMap.measurementUnits[baseMeasurementUnit][noun]}`,
  });

  return (
    <BasePrice
      data-hook={DataHook.BasePriceComponent}
      className={s.basePrice}
      formattedPricePerUnit={formattedPricePerUnit}
      baseQuantity={baseQuantity}
      unitTranslation={unitTranslation}
      screenReaderText={screenReaderText}
      withRTLSupport
    />
  );
};

const BasePriceWithGlobals = withGlobals(BasePriceContainer);

class ProductPriceImpl extends React.Component<IProductPriceProps, any> {
  private readonly sendClickShippingInfoLinkSfEvent = () => {
    const {product, sendClickShippingInfoLinkSf} = this.props;
    sendClickShippingInfoLinkSf(product.id);
  };

  private hasDiscount({
    shouldUseCommonDiscountPricingMethods,
  }: {
    shouldUseCommonDiscountPricingMethods: boolean;
  }): boolean {
    const {product} = this.props;
    if (shouldUseCommonDiscountPricingMethods) {
      return hasAnyDiscount(product);
    }
    return product.discount?.value > 0 || hasAutomaticDiscount(product);
  }

  private renderDiscountRuleName() {
    const {
      product,
      globals: {shouldShowMobile},
    } = this.props;
    const classes = classNames(
      productPriceStyles(productPriceStylable.discountNameRoot, {
        useMobileFont: shouldShowMobile,
      }),
      s.productDiscountRuleName
    );
    const discountRuleName = product.itemDiscount?.discountRuleName;

    return (
      discountRuleName && (
        <Text className={classes} data-hook={DataHook.DiscountRuleName}>
          {discountRuleName}
        </Text>
      )
    );
  }

  private renderPrice() {
    const {
      product,
      textsMap,
      fromPrice,
      shouldUseCommonDiscountPricingMethods,
      shouldAddStrikethroughAndSalePriceDesign,
    } = this.props;

    const hasDiscount = this.hasDiscount({shouldUseCommonDiscountPricingMethods});

    const shouldRenderBasePrice = !!product.formattedPricePerUnit;

    return (
      <>
        <div
          className={classNames(s.prices, {
            [s.newSalePriceMargin]: shouldAddStrikethroughAndSalePriceDesign,
          })}>
          {fromPrice ? (
            <PriceRange
              shouldAddStrikethroughAndSalePriceDesign={shouldAddStrikethroughAndSalePriceDesign}
              formattedFromPrice={fromPrice}
              textsMap={textsMap}
            />
          ) : (
            <RegularPrice
              shouldAddStrikethroughAndSalePriceDesign={shouldAddStrikethroughAndSalePriceDesign}
              shouldUseCommonDiscountPricingMethods={shouldUseCommonDiscountPricingMethods}
              hasDiscount={hasDiscount}
              product={product}
              textsMap={textsMap}
            />
          )}
        </div>
        {shouldRenderBasePrice && <BasePriceWithGlobals product={product} textsMap={textsMap} />}
      </>
    );
  }

  public render() {
    const {
      product,
      allowFreeProducts,
      textsMap,
      shouldUseCommonDiscountPricingMethods,
      shouldMoveDiscountNameUnderPrice,
    } = this.props;
    const isOutOfStock = !product.isInStock;
    const shouldRenderPrices = product.price !== 0 || allowFreeProducts;
    const hasDiscount = this.hasDiscount({shouldUseCommonDiscountPricingMethods});

    const {isRTL} = this.props;
    const {shouldRenderTaxDisclaimer, taxDisclaimer, shippingDisclaimer} = this.props.priceBreakdown;

    const noRenderWhenDiscountedToZero = !(
      !allowFreeProducts &&
      product.comparePrice === 0 &&
      !hasAutomaticDiscount(product) &&
      hasDiscount
    );

    const isPreOrderItem = product.isManageProductItems
      ? product.productItemsPreOrderAvailability !== IProductPreOrderAvailability.NO_VARIANTS
      : product.inventory.availableForPreOrder;

    const shouldRenderOutOfStock = isOutOfStock && !isPreOrderItem;

    return (
      <>
        {shouldRenderOutOfStock && (
          <>
            <ConditionalRender by={'gallery_showPrice'} className={s.priceContainer}>
              <ConditionalRender by={'notShowAddToCartButton'}>
                <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
                  {textsMap.productOutOfStockText}
                </span>
              </ConditionalRender>
              <ConditionalRender by={'showAddToCartButton'} className={s.outOfStockPriceContainer}>
                {this.renderPrice()}
              </ConditionalRender>
            </ConditionalRender>

            {shouldMoveDiscountNameUnderPrice ? (
              <ConditionalRender by={'gallery_showDiscountName'} className={s.discountName}>
                {this.renderDiscountRuleName()}
              </ConditionalRender>
            ) : undefined}

            <ConditionalRender by={'gallery_showPrice'} className={s.priceContainer}>
              <ConditionalRender by={'showAddToCartButton'} className={s.outOfStockPriceContainer}>
                {(shouldRenderTaxDisclaimer || shippingDisclaimer?.show) && (
                  <PriceBreakdown
                    shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
                    taxDisclaimerLabel={taxDisclaimer}
                    shippingDisclaimer={shippingDisclaimer}
                    isDigitalProduct={product.productType === ProductType.DIGITAL}
                    whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
                    isRTL={isRTL}
                    className={s.priceBreakdown}
                    DialogComponent={Dialog}
                  />
                )}
              </ConditionalRender>
            </ConditionalRender>
          </>
        )}

        {!shouldRenderOutOfStock && shouldRenderPrices && noRenderWhenDiscountedToZero && (
          <>
            <ConditionalRender by={'gallery_showPrice'} className={s.priceContainer}>
              {this.renderPrice()}
            </ConditionalRender>

            {shouldMoveDiscountNameUnderPrice ? (
              <ConditionalRender by={'gallery_showDiscountName'} className={s.discountName}>
                {this.renderDiscountRuleName()}
              </ConditionalRender>
            ) : undefined}

            {(shouldRenderTaxDisclaimer || shippingDisclaimer?.show) && (
              <ConditionalRender by={'gallery_showPrice'} className={s.priceContainer}>
                <PriceBreakdown
                  shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
                  taxDisclaimerLabel={taxDisclaimer}
                  shippingDisclaimer={shippingDisclaimer}
                  isDigitalProduct={product.productType === ProductType.DIGITAL}
                  whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
                  isRTL={isRTL}
                  className={s.priceBreakdown}
                  DialogComponent={Dialog}
                />
              </ConditionalRender>
            )}
          </>
        )}
      </>
    );
  }
}

export const ProductPrice = withGlobals(ProductPriceImpl);
